import React, {FC, useState} from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Link } from "react-router-dom";
import { PricingBlock, BlockTitle, BlockPrice, FeatureList, Feature, PricingContainer } from "../Main.styles";
import {useSelector} from "react-redux";
import {selectAuthData} from "store/selectors/auth";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;

const PlanButton = styled(Button)`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 12px 36px;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 10px 25px;
    margin-top: 15px;
  }
`;



const PricingSection: FC<any> = ({isOpenSign, setIsOpenSign}) => {
    const { isAuthenticated } = useSelector(selectAuthData);
    const openSign = () => {

        setIsOpenSign(true)
    }
    return (
        <PricingContainer id="pricing-section">
            {/* Block 1: Inter-Exchange Arbitrage */}
            <PricingBlock>
                <BlockTitle>Inter-Exchange Arbitrage</BlockTitle>
                <FeatureList>
                    <Feature>Inter-Exchange Scanner</Feature>
                    {/*<Feature>Inter-Exchange Telegram Bot</Feature>*/}
                    <Feature>User Chat</Feature>
                    <Feature>Comprehensive Search Tools</Feature>
                </FeatureList>
                <BlockPrice>10 USDT / month</BlockPrice>
                { !isAuthenticated ? (
                    <>
                        <Link to={"/#"}>
                            <PlanButton onClick={openSign} >Subscribe</PlanButton>
                        </Link>
                        <BlockPrice>100 USDT / lifetime</BlockPrice>
                        <Link to="/#">
                            <PlanButton onClick={openSign} >Subscribe</PlanButton>
                        </Link>
                    </>
                ) : (
                    <>
                        <Link  to={"/payment?plan=inter-exchange-arbitrage&price=10&duration=month"}>
                            <PlanButton>Subscribe</PlanButton>
                        </Link>
                        <BlockPrice>100 USDT / lifetime</BlockPrice>
                        <Link to={"/payment?plan=inter-exchange-arbitrage&price=100&duration=lifetime"}>
                            <PlanButton>Subscribe</PlanButton>
                        </Link>
                    </>
                )}
            </PricingBlock>

            {/* Block 2: Funding Bot */}
            <PricingBlock>
                <BlockTitle>Funding Arbitrage</BlockTitle>
                <FeatureList>
                    <Feature>Advanced Filters & Sorting</Feature>
                    <Feature>Real-Time Data Analysis</Feature>
                    <Feature>Deep Market Analysis</Feature>
                </FeatureList>
                <BlockPrice>10 USDT / month</BlockPrice>
                { !isAuthenticated ? (
                    <>
                        <Link to={"/#"}>
                            <PlanButton onClick={openSign} >Subscribe</PlanButton>
                        </Link>
                        <BlockPrice>100 USDT / lifetime</BlockPrice>
                        <Link to="/#">
                            <PlanButton onClick={openSign} >Subscribe</PlanButton>
                        </Link>
                    </>
                ) : (
                    <>
                        <Link  to={"/payment?plan=funding-bot&price=10&duration=month"}>
                            <PlanButton>Subscribe</PlanButton>
                        </Link>
                        <BlockPrice>100 USDT / lifetime</BlockPrice>
                        <Link to={"/payment?plan=funding-bot&price=100&duration=lifetime"}>
                            <PlanButton>Subscribe</PlanButton>
                        </Link>
                    </>
                )}
            </PricingBlock>

            {/* Block 3: Combined Plan */}
            <PricingBlock>
                <BlockTitle>All-Inclusive</BlockTitle>
                <FeatureList>
                    <Feature>All Inter-Exchange Arbitrage Features</Feature>
                    <Feature>All Funding Bot Features</Feature>
                    <Feature>Bundle Discount</Feature>
                </FeatureList>
                <BlockPrice>17 USDT / month</BlockPrice>
                { !isAuthenticated ? (
                    <>
                        <Link to={"/#"}>
                            <PlanButton onClick={openSign} >Subscribe</PlanButton>
                        </Link>
                        <BlockPrice>100 USDT / lifetime</BlockPrice>
                        <Link to="/#">
                            <PlanButton onClick={openSign} >Subscribe</PlanButton>
                        </Link>
                    </>
                ) : (
                    <>
                        <Link  to={"/payment?plan=all-inclusive&price=17&duration=month"}>
                            <PlanButton>Subscribe</PlanButton>
                        </Link>
                        <BlockPrice>100 USDT / lifetime</BlockPrice>
                        <Link to={"/payment?plan=all-inclusive&price=150&duration=lifetime"}>
                            <PlanButton>Subscribe</PlanButton>
                        </Link>
                    </>
                )}
            </PricingBlock>
        </PricingContainer>
    );
};

export default PricingSection;
